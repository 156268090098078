import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import BotImage from '../assets/Grant_photo.webp';
import config from '../config';
import GrantsChatbot from '../components/GrantsChatbot';
import { Modal, Box } from '@mui/material';

const StepThree = ({ userProfile, selectedGrantTypes = [], selectedApplicants = [], onNext }) => {
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentGrantId, setCurrentGrantId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const fullMessage = "Analyzing the grants";
        let i = 0;
        const typingInterval = setInterval(() => {
            setWelcomeMessage(prevMessage => prevMessage + fullMessage[i]);
            i++;
            if (i === fullMessage.length) {
                clearInterval(typingInterval);
                generateQuestions();
            }
        }, 50);

        return () => clearInterval(typingInterval);
    }, []);

    const generateQuestions = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${config.apiHost}/api/ask_grant`, {
                answer: '',
                Question_mode: false,
                detail_grants: false,
                generate_subquestions: true,
                selectedGrantTypes: selectedGrantTypes,
                selectedApplicants: selectedApplicants
            }, {
                auth: {
                    username: 'user',
                    password: 'pass'
                }
            });

            console.log(response)

            // Verifica se a resposta é um JSON válido
            let responseData = response.data.response;
            if (typeof responseData === 'string') {
                responseData = responseData.replace(/```json\n|```/g, '').trim();
                responseData = JSON.parse(responseData);
            }

            setQuestions(responseData.questions);
            onNext({ ...userProfile, selectedGrants: responseData.grants });
        } catch (error) {
            console.error("Error when generating questions:", error);
            setError("Error when generating questions.");
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModal = (grantId) => {
        setCurrentGrantId(grantId);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setCurrentGrantId(null);
        setModalOpen(false);
    };

    return (
        <div style={styles.container}>
            <div style={styles.botContainer}>
                <img src={BotImage} alt="Bot" style={styles.botImage} />
                <h2 style={styles.message}>{welcomeMessage}</h2>
            </div>
            {loading && <ClipLoader color={"#4A90E2"} loading={true} size={50} />}
            {error && <p style={styles.error}>{error}</p>}
            {/* Modal Component */}
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box style={styles.modalBox}>
                    <GrantsChatbot grantId={currentGrantId} all={true} gpt_response={true} initialMessage={"Elabora uma pergunta para ser candidatar a esse grantes para usuario"} suggestions={questions.map(q => q.question)} />
                </Box>
            </Modal>
        </div>
    );
};

export default StepThree;

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        textAlign: 'center',
    },
    botContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    botImage: {
        width: '50px',
        height: '50px',
        marginRight: '10px',
    },
    message: {
        textAlign: 'left',
        maxWidth: '600px',
        fontSize: '14px',
        background: '#8080801c',
        padding: '2%',
        borderRadius: '20px',
    },
    error: {
        color: 'red',
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        backgroundColor: 'white',
        boxShadow: 24,
        padding: 20,
    }
};
