import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import Typing from 'react-typing-effect';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import config from '../config';
import GrantsChatbot from '../components/GrantsChatbot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/chattable.css';

Modal.setAppElement('#root');

const GrantsSearchPage = () => {
  const [filters, setFilters] = useState({
    opened_month: true, // Exibir por padrão as oportunidades abertas neste mês
  });
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filtersVisible, setFiltersVisible] = useState(false); // Estado para controlar a visibilidade dos filtros

  useEffect(() => {
    handleInitialSearch();
  }, [currentPage, filters]);

  const handleInitialSearch = async () => {
    setLoading(true);
    setError(null);
    setResults([]);

    try {
      const response = await axios.get(`${config.apiHost}/api/grants/allopportunities`, {
        auth: {
          username: 'user',
          password: 'pass'
        },
        params: {
          page: currentPage,
          limit: itemsPerPage,
          ...filters
        }
      });
      setResults(response.data.results);
      setTotalPages(Math.ceil(response.data.total_results / itemsPerPage));
    } catch (err) {
      setError('Error fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    setResults([]);

    try {
      const response = await axios.post(`${config.apiHost}/api/conversation`, {
        answer: query,
        all: true
      }, {
        auth: {
          username: 'user',
          password: 'pass'
        }
      });
      setResults(response.data.results);
      setTotalPages(Math.ceil(response.data.total_results / itemsPerPage));
    } catch (err) {
      setError('Error fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setCurrentPage(1);
      handleSearch();
    }
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? checked : value,
    }));
    setCurrentPage(1); // Reset to the first page on filter change
  };

  const openModal = (result) => {
    setModalContent(result);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '900px',
      borderRadius: '20px',
      padding: '30px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
      maxHeight: '80vh',
      overflowY: 'auto'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto', fontFamily: 'Roboto, sans-serif', color: '#333' }}>
      <h1 style={{ textAlign: 'center', color: '#00BFFF', fontSize: '1.8rem' }}>
        <Typing
          text={['Search Grants']}
          speed={100}
          eraseSpeed={50}
          typingDelay={200}
          eraseDelay={2000}
        />
      </h1>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Enter your search query"
          style={{
            padding: '15px',
            width: '100%',
            maxWidth: '600px',
            marginBottom: '10px',
            borderRadius: '30px',
            border: '1px solid #ccc',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
            fontSize: '1rem',
            outline: 'none',
            transition: 'border-color 0.3s ease'
          }}
        />
        <button onClick={() => { setCurrentPage(1); handleSearch(); }} style={{
          padding: '15px 30px',
          borderRadius: '30px',
          backgroundColor: '#4A90E2',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1rem',
          transition: 'background-color 0.3s ease',
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
          width: '100%',
          maxWidth: '200px'
        }}>
          Search
        </button>
      </div>

      {/* Botão para exibir/ocultar filtros */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button onClick={toggleFilters} style={{
          padding: '10px 20px',
          borderRadius: '30px',
          backgroundColor: '#FD9A6F',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1rem',
          transition: 'background-color 0.3s ease',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <FontAwesomeIcon icon={faFilter} style={{ marginRight: '10px' }} />
          {filtersVisible ? 'Hide Filters' : 'Show Filters'}
        </button>
      </div>

      {/* Filtros */}
      {filtersVisible && (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: '20px', marginBottom: '30px' }}>
          <input
            type="text"
            name="agency_name"
            value={filters.agency_name || ''}
            onChange={handleFilterChange}
            placeholder="Agency Name"
            style={{
              padding: '15px',
              width: '100%',
              borderRadius: '30px',
              border: '1px solid #ccc',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
            }}
          />
          <input
            type="date"
            name="posted_date"
            value={filters.posted_date || ''}
            onChange={handleFilterChange}
            placeholder="Posted Date"
            style={{
              padding: '15px',
              width: '100%',
              borderRadius: '30px',
              border: '1px solid #ccc',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
            }}
          />
          <input
            type="date"
            name="close_date"
            value={filters.close_date || ''}
            onChange={handleFilterChange}
            placeholder="Close Date"
            style={{
              padding: '15px',
              width: '100%',
              borderRadius: '30px',
              border: '1px solid #ccc',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
            }}
          />
          <input
            type="text"
            name="category_of_funding_activity"
            value={filters.category_of_funding_activity || ''}
            onChange={handleFilterChange}
            placeholder="Category of Funding Activity"
            style={{
              padding: '15px',
              width: '100%',
              borderRadius: '30px',
              border: '1px solid #ccc',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
            }}
          />
          <input
            type="text"
            name="eligible_applicants"
            value={filters.eligible_applicants || ''}
            onChange={handleFilterChange}
            placeholder="Eligible Applicants"
            style={{
              padding: '15px',
              width: '100%',
              borderRadius: '30px',
              border: '1px solid #ccc',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
            }}
          />
          <input
            type="number"
            name="award_ceiling"
            value={filters.award_ceiling || ''}
            onChange={handleFilterChange}
            placeholder="Award Ceiling"
            style={{
              padding: '15px',
              width: '100%',
              borderRadius: '30px',
              border: '1px solid #ccc',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
            }}
          />
          <input
            type="number"
            name="award_floor"
            value={filters.award_floor || ''}
            onChange={handleFilterChange}
            placeholder="Award Floor"
            style={{
              padding: '15px',
              width: '100%',
              borderRadius: '30px',
              border: '1px solid #ccc',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
              fontSize: '1rem',
            }}
          />
          <label style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              name="closing_this_month"
              checked={filters.closing_this_month || false}
              onChange={(e) => handleFilterChange({ target: { name: e.target.name, value: e.target.checked } })}
              style={{ marginRight: '10px' }}
            />
            Closing This Month
          </label>
          <label style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              name="opened_today"
              checked={filters.opened_today || false}
              onChange={(e) => handleFilterChange({ target: { name: e.target.name, value: e.target.checked } })}
              style={{ marginRight: '10px' }}
            />
            Opened Today
          </label>
          <label style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              name="opened_month"
              checked={filters.opened_month || false} // Allow toggling
              onChange={(e) => handleFilterChange({ target: { name: e.target.name, value: e.target.checked } })}
              style={{ marginRight: '10px' }}
            />
            Opened This Month
          </label>
        </div>
      )}

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <ClipLoader color={"#4A90E2"} loading={loading} size={50} />
        </div>
      ) : (
        <>
          {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
          {Array.isArray(results) && results.length > 0 && (
            <>
              <div style={{
                overflowY: 'auto',
                maxHeight: '80vh', // Increase the height for a better view
                backgroundColor: '#fff',
                borderRadius: '20px',
                padding: '20px',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                marginBottom: '20px' // Add margin to separate from pagination buttons
              }}>
                <table style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  background: '#fff',
                  borderRadius: '20px'
                }}>
                  <thead>
                    <tr style={{ borderBottom: '2px solid #ddd', backgroundColor: '#f9f9f9' }}>
                      <th style={{ padding: '15px', textAlign: 'left', fontSize: '0.9rem' }}>Title</th>
                      <th style={{ padding: '15px', textAlign: 'left', fontSize: '0.9rem' }}>Agency</th>
                      <th style={{ padding: '15px', textAlign: 'left', fontSize: '0.9rem' }}>Posted</th>
                      <th style={{ padding: '15px', textAlign: 'center', fontSize: '0.9rem' }}>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results.map((result, index) => (
                      <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                        <td style={{ padding: '15px', fontSize: '0.85rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                          {result.title}
                        </td>
                        <td style={{ padding: '15px', fontSize: '0.85rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                          {result.agency_name}
                        </td>
                        <td style={{ padding: '15px', fontSize: '0.85rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}>
                          {result.post_date}
                        </td>
                        <td style={{ padding: '15px', textAlign: 'center', maxWidth: '50px' }}>
                          <button onClick={() => openModal(result)} style={{
                            padding: '10px 20px',
                            borderRadius: '30px',
                            backgroundColor: '#1BBCEA',
                            color: '#fff',
                            border: 'none',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease'
                          }}>
                            Detail
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  style={{
                    padding: '10px 15px',
                    margin: '0 5px',
                    borderRadius: '30px',
                    backgroundColor: currentPage === 1 ? '#ccc' : '#FD9A6F',
                    color: '#fff',
                    border: 'none',
                    cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                    transition: 'background-color 0.3s ease'
                  }}
                >
                  Previous
                </button>
                <span style={{ padding: '10px', lineHeight: '30px', fontSize: '1rem' }}>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  style={{
                    padding: '10px 15px',
                    margin: '0 5px',
                    borderRadius: '30px',
                    backgroundColor: currentPage === totalPages ? '#ccc' : '#FD9A6F',
                    color: '#fff',
                    border: 'none',
                    cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
                    transition: 'background-color 0.3s ease'
                  }}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Grant Details"
      >
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Grant Details</h2>
        {modalContent ? (
          <div style={{ textAlign: 'left', marginBottom: '20px', overflowY: 'auto' }}>
            <table style={{
              width: '100%',
              fontFamily: 'Roboto, sans-serif',
              borderCollapse: 'collapse',
              marginBottom: '20px',
              backgroundColor: '#fff',
              borderRadius: '10px'
            }}>
              <tbody>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ padding: '12px', fontWeight: 'bold', backgroundColor: '#f1f1f1' }}>Title:</td>
                  <td style={{ padding: '12px' }}>{modalContent.title}</td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ padding: '12px', fontWeight: 'bold', backgroundColor: '#f1f1f1' }}>Agency:</td>
                  <td style={{ padding: '12px' }}>{modalContent.agency_name}</td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ padding: '12px', fontWeight: 'bold', backgroundColor: '#f1f1f1' }}>Posted Date:</td>
                  <td style={{ padding: '12px' }}>{modalContent.post_date}</td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ padding: '12px', fontWeight: 'bold', backgroundColor: '#f1f1f1' }}>Close Date:</td>
                  <td style={{ padding: '12px' }}>{modalContent.close_date}</td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ padding: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#f1f1f1' }}>Description:</td>
                  <td style={{ padding: '12px' }}><ReactMarkdown>{modalContent.description || 'No description available.'}</ReactMarkdown></td>
                </tr>
                <tr>
                  <td style={{ padding: '12px', fontWeight: 'bold', backgroundColor: '#f1f1f1' }}>More information:</td>
                  <td style={{ padding: '12px' }}><a href={modalContent.url_grantsgov} target="_blank" rel="noopener noreferrer">Link to grant</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <ClipLoader color={"#4A90E2"} loading={true} size={50} />
          </div>
        )}

        <GrantsChatbot grantId={modalContent.opportunity_id} />

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button onClick={closeModal} style={{
            padding: '15px 30px',
            borderRadius: '30px',
            backgroundColor: '#4A90E2',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease'
          }}>
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default GrantsSearchPage;
