import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import BotImage from '../assets/Grant_photo.webp';
import { useTransition, animated } from 'react-spring';
import config from '../config';

const StepOne = ({ userProfile, selectedGrantTypes, onNext }) => {
    const [eligibleApplicants, setEligibleApplicants] = useState([]);
    const [selectedApplicants, setSelectedApplicants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [showApplicants, setShowApplicants] = useState(false);

    useEffect(() => {
        const fullMessage = "Now, let's select the eligible applicants";
        let i = 0;
        const typingInterval = setInterval(() => {
            setWelcomeMessage(prevMessage => prevMessage + fullMessage[i]);
            i++;
            if (i === fullMessage.length) {
                clearInterval(typingInterval);
                setTimeout(() => setShowApplicants(true), 1000);
            }
        }, 50);

        return () => clearInterval(typingInterval);
    }, []);

    useEffect(() => {
        if (showApplicants) {
            const fetchEligibleApplicants = async () => {
                setLoading(true);
                setError(null);
                try {
                    const response = await axios.get(`${config.apiHost}/api/grants/eligible_applicants`, {
                        auth: {
                            username: 'user',
                            password: 'pass'
                        }
                    });
                    // Agrupar os códigos por categoria
                    const applicants = response.data.map(category => ({
                        category: category.category,
                        codes: category.codes.split(', ')
                    }));
                    setEligibleApplicants(applicants);
                } catch (error) {
                    setError("Error when looking for eligible applicants.");
                } finally {
                    setLoading(false);
                }
            };

            fetchEligibleApplicants();
        }
    }, [selectedGrantTypes, showApplicants]);

    const handleApplicantClick = (category, codes) => {
        const applicant = { category, codes };
        if (selectedApplicants.some(app => app.category === category)) {
            setSelectedApplicants(selectedApplicants.filter(app => app.category !== category));
        } else {
            setSelectedApplicants([...selectedApplicants, applicant]);
        }
    };

    const handleSubmit = () => {
        const selectedApplicantCodes = selectedApplicants.flatMap(app => app.codes);
        onNext({ ...userProfile, selectedApplicants: selectedApplicantCodes });
    };

    const transitions = useTransition(eligibleApplicants, {
        keys: item => item.category,
        from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
        enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
        leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
        trail: 100,
    });

    return (
        <div style={styles.container}>
            <div style={styles.botContainer}>
                <img src={BotImage} alt="Bot" style={styles.botImage} />
                <h2 style={styles.message}>{welcomeMessage}</h2>
            </div>
            {loading ? (
                <ClipLoader color={"#4A90E2"} loading={true} size={50} />
            ) : (
                showApplicants && (
                    <div style={styles.grantTypeContainer}>
                        {transitions((style, applicant) => (
                            <animated.div
                                key={applicant.category}
                                title={applicant.category}
                                style={{
                                    ...styles.grantTypeBubble,
                                    ...style,
                                    backgroundColor: selectedApplicants.some(app => app.category === applicant.category) ? '#4A90E2' : '#fd9a6f',
                                }}
                                onClick={() => handleApplicantClick(applicant.category, applicant.codes)}
                            >
                                {applicant.category}
                            </animated.div>
                        ))}
                    </div>
                )
            )}
            {error && <p style={styles.error}>{error}</p>}
            {showApplicants && selectedApplicants.length > 0 && (
                <button onClick={handleSubmit} style={styles.button}>Next Step</button>
            )}
        </div>
    );
};

export default StepOne;

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        textAlign: 'center',
    },
    botContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    botImage: {
        width: '50px',
        height: '50px',
        marginRight: '10px',
    },
    message: {
        textAlign: 'left',
        maxWidth: '600px',
        fontSize: '14px',
        background: '#8080801c',
        padding: '2%',
        borderRadius: '20px',
    },
    grantTypeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '20px 0',
    },
    grantTypeBubble: {
        padding: '15px 25px',
        borderRadius: '25px',
        backgroundColor: '#fd9a6f',
        color: 'white',
        cursor: 'pointer',
        fontSize: '16px',
        margin: '10px',
        transition: 'background-color 0.3s, box-shadow 0.3s',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        position: 'relative',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#4A90E2',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s, transform 0.2s',
    },
    error: {
        color: 'red',
    }
};
