import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import BotImage from '../assets/Grant_photo.webp';
import { useTransition, animated } from 'react-spring';

const StepTwo = ({ userProfile, onNext, onBack }) => {
    const [formData, setFormData] = useState({
        grantType: '',
        grantAmount: '',
    });
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [showGrantType, setShowGrantType] = useState(false);
    const [grantTypes, setGrantTypes] = useState([]);
    const [selectedGrantTypes, setSelectedGrantTypes] = useState([]);

    const grantTypeDescriptions = {
        // Descriptions as provided in your example
    };

    useEffect(() => {
        const fullMessage = "Select a Category ";
        let i = 0;
        const typingInterval = setInterval(() => {
            setWelcomeMessage(prevMessage => prevMessage + fullMessage[i]);
            i++;
            if (i === fullMessage.length) {
                clearInterval(typingInterval);
                setTimeout(() => setShowGrantType(true), 500);
            }
        }, 50);

        return () => clearInterval(typingInterval);
    }, []);

    useEffect(() => {
        const fetchGrantTypes = async () => {
            try {
                const queryString = userProfile.selectedApplicants.map(id => `grant_ids=${id}`).join('&');
                const url = `${config.apiHost}/api/grants/Category?${queryString}`;
                const response = await axios.get(url, {
                    auth: {
                        username: 'user',
                        password: 'pass'
                    }
                });
                setGrantTypes(response.data);
            } catch (error) {
                console.error("Error when looking for types of grants:", error);
            }
        };

        fetchGrantTypes();
    }, [userProfile.selectedApplicants]);

    const handleGrantTypeClick = (grantType) => {
        if (selectedGrantTypes.includes(grantType)) {
            setSelectedGrantTypes(selectedGrantTypes.filter(type => type !== grantType));
        } else {
            setSelectedGrantTypes([...selectedGrantTypes, grantType]);
        }
    };

    const handleSubmit = () => {
        onNext({ ...formData, grantTypes: selectedGrantTypes });
    };

    const transitions = useTransition(showGrantType ? grantTypes : [], {
        keys: grantType => grantType,
        from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
        enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
        leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
        trail: 100,
    });

    return (
        <div style={styles.container}>
            <div style={styles.botContainer}>
                <img src={BotImage} alt="Bot" style={styles.botImage} />
                <h2 style={styles.message}>{welcomeMessage}</h2>
            </div>
            {showGrantType && (
                <div style={styles.grantTypeContainer}>
                    {transitions((style, grantType) => (
                        <animated.div
                            key={grantType}
                            title={grantTypeDescriptions[grantType]}
                            style={{
                                ...styles.grantTypeBubble,
                                ...style,
                                backgroundColor: selectedGrantTypes.includes(grantType) ? '#4A90E2' : '#fd9a6f',
                            }}
                            onClick={() => handleGrantTypeClick(grantType)}
                        >
                            {grantType}
                        </animated.div>
                    ))}
                </div>
            )}
            {showGrantType && (
                <div style={styles.buttonContainer}>
                    <button onClick={onBack} style={styles.backButton}>Back</button>
                    {selectedGrantTypes.length > 0 && (
                        <button onClick={handleSubmit} style={styles.button}>Next Step</button>
                    )}
                </div>
            )}
        </div>
    );
};

export default StepTwo;

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        textAlign: 'center',
    },
    botContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    botImage: {
        width: '50px',
        height: '50px',
        marginRight: '10px',
    },
    message: {
        textAlign: 'left',
        maxWidth: '600px',
        fontSize: '14px',
        background: '#8080801c',
        padding: '2%',
        borderRadius: '20px',
    },
    grantTypeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '20px 0',
    },
    grantTypeBubble: {
        padding: '15px 25px',
        borderRadius: '25px',
        backgroundColor: '#fd9a6f',
        color: 'white',
        cursor: 'pointer',
        fontSize: '16px',
        margin: '10px',
        transition: 'background-color 0.3s, box-shadow 0.3s',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        position: 'relative',
    },
    buttonContainer: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    backButton: {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#bbb',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s, transform 0.2s',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#4A90E2',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s, transform 0.2s',
    }
};
