// config.js
const config = {
   apiHost: 'https://devapi.grants.com/',

  //apiHost: 'http://127.0.0.1:8800',

   
    clientId_paypal: 'ARLKKO1-OA2aaYpq3kzyWAtqtmJyVV-9GHWfCTBIc1lkh7I7P7tEVPCRdwVbyZe_yHXY7ZPg9BZODvAA' ,// Substitua por seu Client ID do PayPal
    plan_1_paypal: 'P-09G51169HA777482FM23JO6Y',
    plan_2_paypal: 'P-3RW060794P5311518M23JR7Y',
    google_client_id:'760800235531-j5hfnm6ephekc7er5ceamgbcm36kqq25.apps.googleusercontent.com',
    default_user_email:'admin@grants.com',
    defaultUserPassword:'89iMp2QIbp0K.:|OHD<r4IT$7VL7}=1);cEH+Il^&0Y@fT[x~'
    
};

export default config;
