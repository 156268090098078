import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './AuthContext';

const AppWrapper = () => {
    return (
        <AuthProvider>
            <App />
        </AuthProvider>
    );
};

ReactDOM.render(
    <BrowserRouter basename="/">
        <AppWrapper />
    </BrowserRouter>,
    document.getElementById('root')
);
