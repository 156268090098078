import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext.js';
import '../assets/css/LoginPage.css';
import config from '../config';

const SignUpPage = () => {
    const { isAuthenticated, login } = useAuth();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 8;
    };

    const handleSignUp = async () => {
        if (!validateEmail(email)) {
            setMessage('Invalid email format');
            return;
        }
        if (!validatePassword(password)) {
            setMessage('Password must be at least 8 characters long');
            return;
        }
        if (password !== repeatPassword) {
            setMessage('Passwords do not match');
            return;
        }
        try {
            // Primeiro, obtenha o token para autenticar o registro
            const tokenResponse = await axios.post(`${config.apiHost}/api/auth/token`, {
                email: config.default_user_email, // E-mail do administrador do config
                password: config.defaultUserPassword // Se
            });
            const tokenData = tokenResponse.data;

            // Em seguida, registre o novo usuário usando o token
            const response = await axios.post(`${config.apiHost}/api/auth/register`, {
                name,
                email,
                password
            }, {
                headers: {
                    Authorization: `Bearer ${tokenData.access_token}`
                }
            });
            const data = response.data;

            setMessage('Sign up successful');
            
            // Fazer login com o novo usuário registrado
            login({ email: data.email, id: data.id }, tokenData.access_token, tokenData.refresh_token);
            navigate('/');
        } catch (error) {
            handleError(error, 'Sign up failed');
        }
    };

    const handleError = (error, defaultMessage) => {
        if (error.response && error.response.data && error.response.data.detail) {
            setMessage(`${defaultMessage}: ${error.response.data.detail}`);
        } else {
            setMessage(`${defaultMessage}: ${error.message}`);
        }
    };

    return (
        <div className="login-container">
            <div className="signUP-content">
                <div className="login-form">
                    <h2 className="login-title">Sign Up</h2>
                    <p className="login-subtitle">Please sign up to access your account and stay connected with our community. Enter your details to continue.</p>
                    <input
                        className="login-input"
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        className="login-input"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        className="login-input"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        className="login-input"
                        type="password"
                        placeholder="Repeat Password"
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                    <button className="login-button" onClick={handleSignUp}>Sign Up</button>
                    <p className="register-link">Already have an account? <a href="/">Sign In Here</a></p>
                    <p className="login-message">{message}</p>
                </div>
                <div className="login-image"></div>
            </div>
        </div>
    );
};

export default SignUpPage;
