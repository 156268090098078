import React from 'react';

const Confirmation = () => {
    return (
        <div style={styles.container}>
            <h2>Obrigado por se Inscrever!</h2>
            <p>Sua assinatura foi confirmada.</p>
            <p>Agora você tem acesso completo aos resultados da IA.</p>
            <p>Dicas sobre como usar o assistente de IA de forma eficaz e um link para um vídeo tutorial ou guia.</p>
            <button onClick={() => { window.location.href = '/grants' }} style={styles.button}>Comece a Explorar Subsídios</button>
        </div>
    );
};

export default Confirmation;

const styles = {
    container: {
        padding: '20px',
        textAlign: 'center',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#4A90E2',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
    }
};
