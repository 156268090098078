import React, { useState } from 'react';

const StepSix = ({ onNext }) => {
    const [formData, setFormData] = useState({
        subscriptionPlan: '',
        cardNumber: '',
        cardExpiry: '',
        cardCVV: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        onNext(formData);
    };

    return (
        <div style={styles.container}>
            <h2>Passo 6: Inscrição para Acesso Completo</h2>
            <label>
                Plano de Assinatura:
                <select name="subscriptionPlan" value={formData.subscriptionPlan} onChange={handleChange}>
                    <option value="">Selecione</option>
                    <option value="Mensal">Mensal</option>
                    <option value="Anual">Anual</option>
                </select>
            </label>
            <label>
                Número do Cartão:
                <input
                    type="text"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleChange}
                    style={styles.input}
                />
            </label>
            <label>
                Validade do Cartão:
                <input
                    type="text"
                    name="cardExpiry"
                    value={formData.cardExpiry}
                    onChange={handleChange}
                    style={styles.input}
                />
            </label>
            <label>
                CVV:
                <input
                    type="text"
                    name="cardCVV"
                    value={formData.cardCVV}
                    onChange={handleChange}
                    style={styles.input}
                />
            </label>
            <button onClick={handleSubmit} style={styles.button}>Completar Inscrição</button>
        </div>
    );
};

export default StepSix;

const styles = {
    container: {
        padding: '20px',
        textAlign: 'center',
    },
    input: {
        padding: '10px',
        width: '100%',
        marginBottom: '20px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#4A90E2',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
    }
};
