import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import config from '../config';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ReactMarkdown from 'react-markdown';
import GrantsChatbot from '../components/GrantsChatbot';  // Ajuste o caminho conforme necessário

const StepFive = ({ userProfile, selectedQuestion, selectedOption, selectedGrantIds, onNext }) => {
    const [selectedGrants, setSelectedGrants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [subscriptionMessage, setSubscriptionMessage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentGrantDetails, setCurrentGrantDetails] = useState(null);
    const [currentGrantId, setCurrentGrantId] = useState(null);
    const [welcomeMessage, setWelcomeMessage] = useState('');

    useEffect(() => {
        const fullMessage ="Here are the top grant opportunities matching your selection:";
        let i = 0;
        const typingInterval = setInterval(() => {
            setWelcomeMessage(prevMessage => prevMessage + fullMessage[i]);
            i++;
            if (i === fullMessage.length) {
                clearInterval(typingInterval);
                fetchGrants();
            }
        }, 50);

        return () => clearInterval(typingInterval);
    }, []);


    useEffect(() => {
        if (Array.isArray(selectedGrantIds) && selectedGrantIds.length > 0) {
            fetchGrants(selectedGrantIds);
        } else {
            console.error("selectedGrantIds is not a valid array or is empty");
        }
    }, [selectedGrantIds, userProfile]);

    const fetchGrants = async (grantIds) => {
        setLoading(true);
        setError(null);
        try {
            const queryString = grantIds.map(id => `opportunity_id=${id}`).join('&');
            const url = `${config.apiHost}/api/grants/allopportunities?${queryString}`;
            const response = await axios.get(url, {
                params: { limit: 1 },
                auth: { username: 'user', password: 'pass' }
            });
            setSelectedGrants(response.data.results);
        } catch (error) {
            setError("");
        } finally {
            setLoading(false);
        }
    };

    const handleProceed = () => {
        if (userProfile.subscriptionPlan) {
            onNext(userProfile);
        } else {
            setSubscriptionMessage('Please purchase a subscription to proceed.');
        }
    };

    const handleOpenModal = async (grant) => {
        setLoading(true);
        setModalOpen(true);
        setCurrentGrantId(grant.opportunity_id);
        try {
            const response = await axios.post(`${config.apiHost}/api/ask_grant`, {
                answer: `Create a report based on the data and return it nicely formatted. : Description: ${grant.description},Title: ${grant.title}, ID: ${grant.opportunity_id}, Award Ceiling: ${grant.award_ceiling}, Award Floor: ${grant.award_floor}, Category: ${grant.category_of_funding_activity}, Agency: ${grant.agency_name}, Eligible Applicants: ${grant.eligible_applicants}, Close Date: ${grant.close_date}`,
                detail_grants: true
            }, {
                auth: { username: 'user', password: 'pass' }
            });
            setCurrentGrantDetails(response.data.response);
        } catch (error) {
            console.error("Error when fetching detailed grant information:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setCurrentGrantDetails(null);
        setCurrentGrantId(null);
    };

    return (
        <div style={styles.container}>
            <h2>Result</h2>
            
           
            {error && <p style={styles.error}>{error}</p>}
            <div style={styles.suggestionsContainer}>
                {selectedGrants.length > 0 ? (
                    selectedGrants.map((grant, index) => (
                        <div key={index} style={styles.suggestionObject}>
                            <h3>{grant.title}</h3>
                            <p>Eligible Applicants: {grant.eligible_applicants}</p>
                            <p>Award Floor: {grant.award_floor}</p>
                            <p>Award Ceiling: {grant.award_ceiling}</p>
                            <p>Estimated Total Program Funding: {grant.estimated_total_program_funding}</p>
                            <p>Agency: {grant.agency_name}</p>
                            <p>Close Date: {grant.close_date}</p>
                            <button onClick={() => handleOpenModal(grant)} style={styles.infoButton}>Info</button>
                            <div style={styles.blurred}>
                            <p>Subscribe to see more data</p>
                            </div>
                            <div style={styles.blurred}>
                            <p>Subscribe to see more data</p>
                            </div>
                            <div style={styles.blurred}>
                            <p>Subscribe to see more data</p>
                            </div>
                            <div style={styles.blurred}>
                            <p>Subscribe to see more data</p>
                            </div>
                            <div style={styles.blurred}>
                            <p>Subscribe to see more data</p>
                            </div>
                            <div style={styles.blurred}>
                            <p>Subscribe to see more data</p>
                            </div>
                            <div style={styles.blurred}>
                            <p>Subscribe to see more data</p>
                            </div>
                            <div style={styles.blurred}>
                            <p>Subscribe to see more data</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No grants found for the selected options.</p>
                )}
            </div>
            {subscriptionMessage && <p style={styles.subscriptionMessage}>{subscriptionMessage}</p>}
            <button onClick={handleProceed} style={styles.button}>Proceed</button>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                closeAfterTransition
                BackdropProps={{ timeout: 500 }}
            >
                <Box style={styles.modalBox}>
                    {loading ? (
                        <ClipLoader color={"#4A90E2"} loading={true} size={50} />
                    ) : (
                        currentGrantDetails && (
                            <div style={styles.insightContent}>
                                <h2>Grant Details</h2>
                                <ReactMarkdown>{currentGrantDetails}</ReactMarkdown>
                                <GrantsChatbot grantId={currentGrantId} all={true} gpt_response={true}  />
                            </div>
                        )
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default StepFive;
const styles = {
    container: {
        padding: '20px',
        textAlign: 'center',
    },
    suggestionsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: '20px',
    },
    suggestionObject: {
        padding: '10px',
        margin: '10px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
        width: '100%',
        textAlign: 'left',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#4A90E2',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
    },
    infoButton: {
        padding: '5px 10px',
        borderRadius: '5px',
        backgroundColor: '#4A90E2',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        marginTop: '10px',
    },
    error: {
        color: 'red',
    },
    subscriptionMessage: {
        color: 'red',
        marginTop: '10px',
    },
    blurred: {
        padding: '10px',
        margin: '10px 0',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f1f1f1',
        color: '#aaa',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        maxHeight: '80%',
        backgroundColor: 'white',
        boxShadow: 24,
        padding: '20px',
        borderRadius: '10px',
        overflowY: 'auto',
    },
    insightContent: {
        textAlign: 'left',
    },
};
