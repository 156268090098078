import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const LandingPage = ({ onNext }) => {
    const [grantTypes, setGrantTypes] = useState([]);
    const [selectedGrants, setSelectedGrants] = useState([]);

    useEffect(() => {
      
    }, []);

    const handleGrantTypeClick = (grantType) => {
        if (selectedGrants.includes(grantType)) {
            setSelectedGrants(selectedGrants.filter(type => type !== grantType));
        } else {
            setSelectedGrants([...selectedGrants, grantType]);
        }
    };

    const handleNext = () => {
        onNext({ grantTypes: selectedGrants });
    };

    return (
        <div style={styles.container}>
            <h1>Unlock Your Grant Opportunities with AI Assistance</h1>
            <p>Start your journey to find the perfect grants</p>
            <div style={styles.grantTypeContainer}>
                {grantTypes.map((grantType, index) => (
                    <div
                        key={index}
                        style={{
                            ...styles.grantTypeBubble,
                            backgroundColor: selectedGrants.includes(grantType) ? '#4A90E2' : '#fd9a6f',
                        }}
                        onClick={() => handleGrantTypeClick(grantType)}
                    >
                        {grantType}
                    </div>
                ))}
            </div>
            <button onClick={handleNext} style={styles.button}>Start Now</button>
        </div>
    );
};

export default LandingPage;

const styles = {
    container: {
        padding: '20px',
        textAlign: 'center',
    },
    grantTypeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '20px 0',
    },
    grantTypeBubble: {
        padding: '10px 20px',
        borderRadius: '25px',
        backgroundColor: '#fd9a6f',
        color: 'white',
        cursor: 'pointer',
        fontSize: '14px',
        margin: '5px',
        transition: 'background-color 0.3s',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#4A90E2',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
    }
};
