import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import '../assets/css/SubscriptionPage.css';
import config from '../config';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import Modal from 'react-modal';

// Configure o elemento onde o modal será renderizado
Modal.setAppElement('#root');

const SubscriptionPage = () => {
    const { user,logout, } = useAuth(); // Obter o usuário autenticado
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar o modal
    const [subscriptionSuccessMessage, setSubscriptionSuccessMessage] = useState(''); // Mensagem de sucesso
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setIsLoading(false);
        }
    }, [user]);

    if (user?.subscription_status === 'active') {
        return <Navigate to="/" />;
    }

    const initialOptions = {
        "client-id": config.clientId_paypal, // Substitua por seu Client ID do PayPal
        currency: "USD",
        intent: "subscription",
        vault: true // Adicione esta linha
    };

    const handleSubscriptionSuccess = async (data, planId, userId) => {
        try {
            // Primeiro, obtenha o token para autenticar o login
            const tokenResponse = await axios.post(`${config.apiHost}/api/auth/token`, {
                email: config.default_user_email, // E-mail do administrador do config
                password: config.defaultUserPassword // Senha do administrador do config
            });
            const tokenData = tokenResponse.data;

            // Em seguida, faça a requisição para atualizar o status da assinatura
            const response = await axios.post(`${config.apiHost}/api/update-subscription-status`, {
                subscriptionId: data.subscriptionID,
                userId: userId,
                planId: planId,
                subscribe_id: data.subscriptionID,
                status: 'active',
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenData.access_token}` // Use o token para autenticar a requisição
                }
            });

            if (response.status === 200) {
                // Define a mensagem de sucesso e abre o modal
                setSubscriptionSuccessMessage('Subscription updated successfully');
                setIsModalOpen(true);
            } else {
                alert('Failed to update subscription status');
            }
        } catch (error) {
            console.error('Error updating subscription status:', error);
        }
    };

    // Função para fechar o modal e redirecionar para "/"
    const closeModalAndRedirect = () => {
        setIsModalOpen(false);
        logout();
    };

    // Verifique se o usuário está disponível
    if (isLoading || !user) {
        return <div>Loading...</div>; // Ou redirecione para a página de login
    }

    console.log("valor do usuário", user.id);

    return (
        <PayPalScriptProvider options={initialOptions}>
            <div className="subscription-container">
                <h1>Choose Your Plan</h1>
                <div className="plans">
                    <div className="plan">
                        <h2>Basic</h2>
                        <p>Access to our grant database and AI recommendations. Perfect for individuals and small organizations just starting their grant search.</p>
                        <div className="price">$19/mo</div>
                        <PayPalButtons 
                            style={{ layout: "vertical" }} 
                            createSubscription={(data, actions) => {
                                return actions.subscription.create({
                                    plan_id: "P-09G51169HA777482FM23JO6Y" // Substitua pelo ID do seu plano básico do PayPal
                                });
                            }}
                            onApprove={(data, actions) => {
                                console.log('Subscription completed');
                                handleSubscriptionSuccess(data, "P-09G51169HA777482FM23JO6Y", user.id);
                            }}
                            onError={(err) => {
                                console.error('Subscription failed: ', err);
                            }}
                        />
                    </div>
                    <div className="plan">
                        <h2>Pro</h2>
                        <p>All Basic features plus premium support and advanced search tools. Ideal for organizations with ongoing funding needs.</p>
                        <div className="price">$45 /mo</div>
                        <PayPalButtons 
                            style={{ layout: "vertical" }} 
                            createSubscription={(data, actions) => {
                                return actions.subscription.create({
                                    plan_id: "P-3RW060794P5311518M23JR7Y" // Substitua pelo ID do seu plano Pro do PayPal
                                });
                            }}
                            onApprove={(data, actions) => {
                                console.log('Subscription completed');
                                handleSubscriptionSuccess(data, "P-3RW060794P5311518M23JR7Y", user.id);
                            }}
                            onError={(err) => {
                                console.error('Subscription failed: ', err);
                            }}
                        />
                    </div>
                    <div className="plan">
                        <h2>Enterprise</h2>
                        <p>Comprehensive access including dedicated consultant support and custom AI recommendations. Best for large organizations and enterprises.</p>
                        <div className="price">Contact us for pricing</div>
                        <button className="btn" onClick={() => ""}>Get Started</button>
                    </div>
                </div>
            </div>

            {/* Modal de sucesso */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModalAndRedirect} // O modal não pode ser fechado manualmente, apenas redireciona
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',
                        padding: '20px',
                        textAlign: 'center',
                        zIndex: '10000', // Define um z-index alto para garantir que fique acima de tudo
                        position: 'fixed' // Garante que o modal fique fixo
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.85)', // Fundo escuro mais opaco
                        zIndex: '9999', // Garanta que a sobreposição tenha um z-index alto
                    }
                }}
            >
                <h2>{subscriptionSuccessMessage}</h2>
                <p>Your subscription has been successfully updated.</p>
                <button
                    onClick={closeModalAndRedirect}
                    style={{
                        padding: '10px 20px',
                        borderRadius: '5px',
                        backgroundColor: '#4A90E2',
                        color: '#fff',
                        border: 'none',
                        cursor: 'pointer',
                        marginTop: '20px',
                        fontSize: '16px',
                    }}
                >
                  Please log in again to continue.
                </button>
            </Modal>
        </PayPalScriptProvider>
    );
};

export default SubscriptionPage;
