import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import GrantsChatbot from './components/GrantsChatbot';
import GrantsPage from './page/GrantsPage';
import GrantsWizard from './page/GrantsWizard';
import LoginPage from './page/LoginPage';
import SignUpPage from './page/SignUpPage';
import MembersArea from './page/MembersArea';
import GrantsTable from './page/GrantsTable';
 
import ProtectedRoute from './ProtectedRoute';
import logo from './assets/logogrants.webp';
import ConfirmEmailPage from './page/confirm_email';
import UserPage from './page/UserPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './assets/css/App.css';
import { useAuth } from './AuthContext';
import InteractiveWizard from './page/InteractiveWizard';
import SubscriptionPage from './page/SubscriptionPage'; // Certifique-se de que o caminho está correto

const App = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { isAuthenticated, logout, token } = useAuth();
    const location = useLocation();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const hideHeaderFooter = location.pathname === '/login' || location.pathname === '/signup';

    const SocialLinks = () => (
        <div style={styles.socialLinks}>
            <a href="https://instagram.com" style={styles.socialLink}>
                <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://linkedin.com" style={styles.socialLink}>
                <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://twitter.com" style={styles.socialLink}>
                <FontAwesomeIcon icon={faTwitter} />
            </a>
        </div>
    );

    return (
        <div style={styles.container}>
            {!hideHeaderFooter && (
                <header className="header">
                    <div className="logo-container">
                        <img src={logo} alt="Logo" className="logo" />
                        <h1 className="title"></h1>
                    </div>
                    <nav className="nav">
                        <button className="hamburger" onClick={toggleMenu}>
                            ☰
                        </button>
                        <ul className={`nav-list ${menuOpen ? 'open' : ''}`}>
                            <li className="nav-item">
                                <Link to="/" className="nav-link">AI Assistance</Link>
                            </li>
                          {/*    <li className="nav-item">
                                <Link to="/grants-wizard" className="nav-link">Grants Wizard</Link>
                            </li>*/}
                          

                          <li className="nav-item">
                                <Link to="/Search_Grants" className="nav-link">Search Grants</Link>
                            </li>


                         
                          
                            <li className="nav-item">
                                <Link to="/interactive-wizard" className="nav-link">Interactive Wizard</Link>
                            </li>
                            {isAuthenticated ? (
                                <>
                                  {/*    <li className="nav-item">
                                        <Link to="/members" className="nav-link">Members Area</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/user" className="nav-link">User</Link>
                                    </li>*/}
                                    <li className="nav-item">
                                        <button onClick={logout} className="nav-link logout-button">Logout</button>
                                    </li> 
                                </>
                            ) : (
                                <>
                                    <li className="nav-item">
                                        <Link to="/login" className="nav-link">Login</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/signup" className="nav-link">Sign Up</Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </nav>
                </header>
            )}

            <div style={styles.content}>
                <Routes>
                    <Route path="/" element={<ProtectedRoute><GrantsChatbot /></ProtectedRoute>} />
                    <Route path="/grants" element={<ProtectedRoute><GrantsPage /></ProtectedRoute>} />
                    <Route path="/grants-wizard" element={<ProtectedRoute><GrantsWizard /></ProtectedRoute>} />
                    <Route path="/Search_Grants" element={<ProtectedRoute><GrantsTable /></ProtectedRoute>} />
                    <Route path="/interactive-wizard" element={<InteractiveWizard />} />
                    <Route path="/confirm-email" element={<ConfirmEmailPage />} />
                    <Route path="/members" element={<ProtectedRoute><MembersArea /></ProtectedRoute>} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/user" element={<UserPage />} />

                    
                    <Route path="/subscribe" element={<SubscriptionPage />} /> {/* Adicione esta linha */}
                </Routes>
            </div>

            {!hideHeaderFooter && (
                <footer style={styles.footer}>
                    <div style={styles.footerContent}>
                        <div style={styles.footerLeft}>
                            <img src={logo} alt="Logo" style={styles.footerLogo} />
                            <p>Explore our extensive database, get AI assistance, and connect with expert consultants.</p>
                        </div>
                        <div style={styles.footerLinks}>
                            <div style={styles.footerColumn}>
                                <Link to="/" className="footer-link">Home</Link>
                                <Link to="/about" className="footer-link">About Us</Link>
                                <Link to="/grants" className="footer-link">Grant Database</Link>
                                <Link to="/ai-assistance" className="footer-link">AI Assistance</Link>
                            </div>
                            <div style={styles.footerColumn}>
                                <Link to="/consultants" className="footer-link">Consultants</Link>
                                <Link to="/pricing" className="footer-link">Pricing</Link>
                                <Link to="/blog" className="footer-link">Blog</Link>
                                <Link to="/contact" className="footer-link">Contact Us</Link>
                            </div>
                            <div style={styles.footerColumn}>
                                <Link to="/privacy" className="footer-link">Privacy Policy</Link>
                                <Link to="/terms" className="footer-link">Terms of Service</Link>
                                <Link to="/faq" className="footer-link">FAQ</Link>
                                <Link to="/support" className="footer-link">Support</Link>
                            </div>
                        </div>
                    </div>
                    <div style={styles.footerBottom}>
                        <p>&copy; 2024 Grants, All Rights Reserved</p>
                        <SocialLinks />
                    </div>
                </footer>
            )}
        </div>
    );
};

const styles = {
    container: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    header: {
        backgroundColor: '#f0f0f0',
        padding: '10px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        width: '100%',
        boxSizing: 'border-box'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        height: '40px',
        marginRight: '10px'
    },
    title: {
        fontSize: '24px',
        color: '#333',
        margin: 0
    },
    nav: {
        display: 'flex',
        alignItems: 'center'
    },
    navList: {
        listStyle: 'none',
        display: 'flex',
        padding: 0,
        margin: 0
    },
    navItem: {
        margin: '0 15px'
    },
    navLink: {
        color: '#333',
        textDecoration: 'none',
        fontSize: '18px',
        fontWeight: 'bold',
        transition: 'color 0.3s ease'
    },
    contactButton: {
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#0078FF',
        color: 'white',
        cursor: 'pointer',
        fontSize: '16px',
        marginLeft: '20px'
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(to bottom, #e9f8fd 0%, white 70%, white 30%)'
    },
    footer: {
        padding: '20px 0',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    footerContent: {
        width: '100%',
        maxWidth: '1200px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0 20px',
        boxSizing: 'border-box'
    },
    footerLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: '300px'
    },
    footerLogo: {
        height: '60px',
        marginBottom: '10px'
    },
    footerLinks: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '60%'
    },
    footerColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: '30px'
    },
    footerBottom: {
        width: '100%',
        maxWidth: '1200px',
        display: 'flex',
        backgroundColor: '#e9f8fd',
        borderRadius: '20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        padding: '0 20px',
        boxSizing: 'border-box'
    },
    socialLinks: {
        display: 'flex',
        alignItems: 'center'
    },
    socialLink: {
        color: '#333',
        textDecoration: 'none',
        marginLeft: '15px',
        fontSize: '14px',
        transition: 'color 0.3s ease'
    },
    '@media (max-width: 768px)': {
        header: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        nav: {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        hamburger: {
            display: 'block',
            background: 'none',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
            padding: '0',
            marginLeft: 'auto'
        },
        navList: {
            display: 'none',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%'
        },
        navListOpen: {
            display: 'flex'
        },
        navItem: {
            margin: '5px 0'
        },
        contactButton: {
            marginLeft: 0,
            marginTop: '10px'
        },
        footerContent: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        footerLinks: {
            width: '100%',
            flexDirection: 'column'
        },
        footerColumn: {
            marginLeft: 0,
            marginBottom: '10px'
        },
        footerBottom: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '10px 20px'
        },
        socialLink: {
            marginLeft: '0',
            marginTop: '5px'
        }
    },
    '@media (max-width: 768px)': {
        header: {
            padding: '10px'
        },
        title: {
            fontSize: '20px'
        },
        navLink: {
            fontSize: '16px'
        },
        contactButton: {
            padding: '8px 16px',
            fontSize: '14px'
        },
        footerLeft: {
            maxWidth: '100%'
        },
        footerLogo: {
            height: '30px'
        },
        footerLinks: {
            display: 'block',
            justifyContent: 'space-between',
            width: '60%'
        }
    }
};

export default App;
