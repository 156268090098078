import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext.js';

const MembersArea = () => {
    const [grants, setGrants] = useState([]);
    const { isAuthenticated, user } = useAuth();

    useEffect(() => {
        const fetchGrants = async () => {
            try {
                const response = await axios.get('http://localhost:8000/api/grants/opportunities', {
                    auth: {
                        username: user.username,
                        password: user.password,
                    },
                });
                setGrants(response.data);
            } catch (error) {
                console.error('Error fetching grants', error);
            }
        };

        if (isAuthenticated) {
            fetchGrants();
        }
    }, [isAuthenticated, user]);

    if (!isAuthenticated) {
        return <p>Please log in to view this page.</p>;
    }

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Member Area - Grants and Reports</h2>
            <div style={styles.grantsContainer}>
                {grants.map((grant) => (
                    <div key={grant.opportunity_id} style={styles.grantCard}>
                        <h3 style={styles.grantTitle}>{grant.title}</h3>
                        <p>{grant.description}</p>
                        <p><strong>Agency:</strong> {grant.agency_name}</p>
                        <p><strong>Funding Instrument Type:</strong> {grant.funding_instrument_type}</p>
                        <p><strong>CFDA Numbers:</strong> {grant.cfda_numbers}</p>
                        <p><strong>Posted Date:</strong> {new Date(grant.post_date).toLocaleDateString()}</p>
                        <p><strong>Close Date:</strong> {new Date(grant.close_date).toLocaleDateString()}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        textAlign: 'center',
    },
    title: {
        fontSize: '24px',
        marginBottom: '20px',
    },
    grantsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    grantCard: {
        background: '#fff',
        padding: '20px',
        margin: '10px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '80%',
        textAlign: 'left',
    },
    grantTitle: {
        fontSize: '20px',
        marginBottom: '10px',
    }
};

export default MembersArea;
