import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const ConfirmEmailPage = () => {
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            axios.get(`${config.apiHost}/api/auth/confirm-email`, { params: { token } })
                .then(response => {
                    setMessage('Email confirmed successfully');
                    setTimeout(() => navigate('/login'), 3000); // Redireciona para login após 3 segundos
                })
                .catch(error => {
                    setMessage('Failed to confirm email');
                });
        } else {
            setMessage('Invalid confirmation link');
        }
    }, [searchParams, navigate]);

    return (
        <div className="confirmation-container">
            <h2>{message}</h2>
        </div>
    );
};

export default ConfirmEmailPage;
