import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import BotImage from '../assets/Grant_photo.webp'; // Adicione a imagem do bot
import config from '../config';

const StepFour = ({ userProfile, onNext }) => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [showQuestions, setShowQuestions] = useState(false);

    useEffect(() => {
        const fullMessage = "Using AI to generate the best questions for you to select the right grant opportunities.";
        let i = 0;
        const typingInterval = setInterval(() => {
            setWelcomeMessage(prevMessage => prevMessage + fullMessage[i]);
            i++;
            if (i === fullMessage.length) {
                clearInterval(typingInterval);
                setTimeout(() => setShowQuestions(true), 1000);
            }
        }, 50);

        return () => clearInterval(typingInterval);
    }, []);

    useEffect(() => {
        console.log("userProfile in StepFour:", userProfile); // Log para depuração
        if (userProfile && userProfile.selectedGrants) {
            fetchQuestions(userProfile.selectedGrants);
        }
    }, [userProfile]);

    const fetchQuestions = async (selectedGrants) => {
        setLoading(true);
        setError(null);
        try {
            const grantsText = selectedGrants.map(grant => (
                `Title: ${grant.title}, ID: ${grant.opportunity_id}, Award Ceiling: ${grant.award_ceiling}, Award Floor: ${grant.award_floor}, Category: ${grant.category_of_funding_activity}, Agency: ${grant.agency_name}, Eligible Applicants: ${grant.eligible_applicants}, Close Date: ${grant.close_date}`
            )).join('; ');
            const response = await axios.post(`${config.apiHost}/api/ask_grant`, {
                answer: grantsText,
                Question_mode: true
            }, {
                auth: {
                    username: 'user',
                    password: 'pass'
                }
            });

            // Tentar carregar o JSON para garantir que esteja bem formatado
            let parsedResponse;
            try {
                parsedResponse = response.data.response ;
                console.log("Selected grants:", grantsText);
                console.log("Response from API:", parsedResponse);
                setQuestions(parsedResponse.questions);
            } catch (jsonError) {
                console.error("Error parsing JSON:", jsonError);
                setError("Error when fetching questions. Invalid JSON format.");
            }
        } catch (error) {
            console.error("Error when fetching questions:", error);
            setError("Error when fetching questions.");
        } finally {
            setLoading(false);
        }
    };

    const handleOptionClick = (question, option) => {
        const selectedGrantIds = option.grant_ids;
        onNext({ ...userProfile, selectedQuestion: question, selectedOption: option.label, selectedGrantIds });
    };

    return (
        <div style={styles.container}>
            <div style={styles.botContainer}>
                <img src={BotImage} alt="Bot" style={styles.botImage} />
                <h2 style={styles.message}>{welcomeMessage}</h2>
            </div>
            {loading && <ClipLoader color={"#4A90E2"} loading={true} size={50} />}
            {error && <p style={styles.error}>{error}</p>}
            {showQuestions && (
                <div style={styles.questionsContainer}>
                    {questions.map((question, qIndex) => (
                        <div key={qIndex} style={styles.questionBlock}>
                            <h3>{question.question}</h3>
                            {question.options.map((option, oIndex) => (
                                <button 
                                    key={oIndex} 
                                    style={styles.questionButton} 
                                    onClick={() => handleOptionClick(question.question, option)}
                                >
                                    {option.label}
                                </button>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default StepFour;

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        textAlign: 'center',
    },
    botContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    botImage: {
        width: '50px',
        height: '50px',
        marginRight: '10px',
    },
    message: {
        textAlign: 'left',
        maxWidth: '600px',
        fontSize: '14px',
        background: '#8080801c',
        padding: '2%',
        borderRadius: '20px',
    },
    questionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    questionBlock: {
        marginBottom: '20px',
    },
    questionButton: {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#4A90E2',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        margin: '5px',
        width: '100%',
        maxWidth: '600px',
        textAlign: 'left',
    },
    error: {
        color: 'red',
    },
};
