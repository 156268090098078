import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import config from './config';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedAuth = localStorage.getItem('isAuthenticated');
        const storedUser = localStorage.getItem('user');
        const storedToken = localStorage.getItem('token');
        const storedRefreshToken = localStorage.getItem('refreshToken');

        if (storedAuth) {
            setIsAuthenticated(JSON.parse(storedAuth));
        }
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
        if (storedToken) {
            setToken(storedToken);
        }
        if (storedRefreshToken) {
            setRefreshToken(storedRefreshToken);
        }
    }, []);

    useEffect(() => {
        if (user || token) {
            console.log('AuthContext user atualizado:', user);
        }
    }, [user, token]);

    const login = async (userData, accessToken, refreshToken) => {
        console.log('Logging in with token:', accessToken);
    
        const completeUserData = {
            ...userData,
            id: userData.id || 'example-id',
            is_confirmed: userData.is_confirmed,
            subscription_status: null,  // Inicialmente nulo
        };
    
        setIsAuthenticated(true);
        setUser(completeUserData);
        setToken(accessToken);
        setRefreshToken(refreshToken);
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('user', JSON.stringify(completeUserData));
        localStorage.setItem('token', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
    
        try {
            // Obtenha o token de administrador
            const adminTokenResponse = await axios.post(`${config.apiHost}/api/auth/token`, {
                email: config.default_user_email,
                password: config.defaultUserPassword
            });
            const adminToken = adminTokenResponse.data.access_token;
    
            // Busque o status de inscrição atualizado
            const response = await axios.get(`${config.apiHost}/api/subscription-status`, {
                params: { email: completeUserData.email },
                headers: {
                    'Authorization': `Bearer ${adminToken}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
            });
            
            const subscriptionData = response.data;
    
            // Atualize o estado do usuário com o status de inscrição atualizado
            const updatedUserData = {
                ...completeUserData,
                subscription_status: subscriptionData.subscription_status,
            };
            setUser(updatedUserData);
            localStorage.setItem('user', JSON.stringify(updatedUserData));
    
            console.log("Subscription status atualizado:", subscriptionData.subscription_status);
    
        } catch (error) {
            console.error('Error checking subscription status:', error);
        }
    
        if (completeUserData.is_confirmed === false) {
            // navigate('/login');
        }
    };
    const logout = (navigate) => {
        // Limpar todos os estados relacionados à autenticação
        setIsAuthenticated(false);
        setUser(null);
        setToken(null);
        setRefreshToken(null);
    
        // Limpar todos os itens do localStorage relacionados à autenticação
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
    
        // Limpar o sessionStorage, caso você também tenha usado ele
        sessionStorage.clear();
    
        // Limpar todo o localStorage, caso deseje remover todos os itens
        localStorage.clear();
    
        // Limpar caches do service worker (caso esteja usando Progressive Web App - PWA)
        if ('caches' in window) {
            caches.keys().then((cacheNames) => {
                cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                });
            });
        }
    
        // Redirecionar o usuário para a página de login
        navigate('/login');
    
        // Recarregar a página para garantir que todos os estados e caches do React sejam reiniciados
        window.location.reload();
    };




    const refreshAccessToken = async () => {
        try {
            const response = await axios.post(`${config.apiHost}/api/auth/refresh`, {}, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            const newToken = response.data.token;
            console.log('New access token:', newToken);
            setToken(newToken);
            localStorage.setItem('token', newToken);
        } catch (error) {
            logout(navigate);
        }
    };

    return (
        <GoogleOAuthProvider clientId={config.google_client_id}>
            <AuthContext.Provider value={{ isAuthenticated, user, login, logout: () => logout(navigate), token, refreshAccessToken }}>
                {children}
            </AuthContext.Provider>
        </GoogleOAuthProvider>
    );
};

export const useAuth = () => useContext(AuthContext);
