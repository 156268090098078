import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../AuthContext';
import '../assets/css/LoginPage.css';
import config from '../config';

const LoginPage = () => {
    const { isAuthenticated, login } = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleGoogleLoginSuccess = async (credentialResponse) => {
        try {
            const { credential } = credentialResponse;
            const response = await axios.post(`${config.apiHost}/api/auth/callback`, { token: credential });
            const data = response.data;
            login({ email: data.email, id: data.id }, data.token, data.refresh_token);
            setMessage('Login successful');
            navigate('/');
        } catch (error) {
            handleError(error, 'Google login failed');
        }
    };

    const handleGoogleLoginFailure = (error) => {
        setMessage('Google login failed');
    };

    const handleLogin = async () => {
        // Verifica se os campos de email e senha estão preenchidos
        if (email.trim() === '' || password.trim() === '') {
            setMessage('Please fill in both email and password');
            return;
        }

        try {
            // Primeiro, obtenha o token para autenticar o login
            const tokenResponse = await axios.post(`${config.apiHost}/api/auth/token`, {
                email: config.default_user_email, // E-mail do administrador do config
                password: config.defaultUserPassword // Senha do administrador do config
            });
            const tokenData = tokenResponse.data;

            // Em seguida, faça o login do usuário usando o token
            const response = await axios.post(`${config.apiHost}/api/auth/login`, {
                email,
                password
            }, {
                headers: {
                    Authorization: `Bearer ${tokenData.access_token}`
                }
            });
            const data = response.data;
            login({ email: data.email, id: data.id, is_confirmed: data.is_confirmed }, tokenData.access_token, tokenData.refresh_token);
            setMessage('Login successful');
            navigate('/');
        } catch (error) {
            handleError(error, 'Login failed');
        }
    };

    const handleError = (error, defaultMessage) => {
        if (error.response && error.response.data && error.response.data.detail) {
            setMessage(`${defaultMessage}: ${error.response.data.detail}`);
        } else {
            setMessage(`${defaultMessage}: ${error.message}`);
        }
    };

    return (
        <div className="login-container">
            <div className="login-content">
                <div className="login-form">
                    <h2 className="login-title">Sign In</h2>
                    <p className="login-subtitle">Please sign in to access your account and stay connected with our community. Enter your email and password to continue.</p>
                    <input
                        className="login-input"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        className="login-input"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <a href="#" className="forgot-password">Forgot Password?</a>
                    <button className="login-button" onClick={handleLogin}>Sign In</button>
                    <GoogleLogin
                        onSuccess={handleGoogleLoginSuccess}
                        onError={handleGoogleLoginFailure}
                        className="google-login-button"
                    />
                    <p className="register-link">Don’t have an account? <a href="signup">Register Here</a></p>
                    <p className="login-message">{message}</p>
                </div>
                <div className="login-image"></div>
            </div>
        </div>
    );
};

export default LoginPage;
