import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, user, logout } = useAuth(); // Obtenha o usuário do contexto
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
    }, [isAuthenticated]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        logout(); // Deslogar se o usuário não estiver autenticado
        return <Navigate to="/login" />;
    }

    // Verifique se a assinatura do usuário está ativa
    if (user?.subscription_status !== 'active') {
        return <Navigate to="/subscribe" />;
    }

    return children;
};
 

export default ProtectedRoute;
