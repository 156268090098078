import React, { useState } from 'react';
import LandingPage from './LandingPage';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import Confirmation from './Confirmation';
import '../assets/css/GrantsWizard.css';

const ProgressBar = ({ currentStep }) => {
    const steps = ['Home', 'Select Grants Category', 'Select Eligible Applicants', 'Filter Grants', 'Generate Question','Grants'];
    
    return (
        <div className="progress-container">
            {steps.map((step, index) => (
                <div key={index} className={`progress-step ${currentStep === index ? 'current' : currentStep > index ? 'completed' : ''}`}>
                    {step}
                </div>
            ))}
        </div>
    );
};

const GrantsWizard = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [userProfile, setUserProfile] = useState({});
    const [selectedGrantTypes, setSelectedGrantTypes] = useState([]);
    const [selectedApplicants, setSelectedApplicants] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedGrantIds, setSelectedGrantIds] = useState([]);

    const handleNextStep = (data) => {
        if (currentStep === 0) {
            setCurrentStep(1);
        } else if (currentStep === 1) {
            setUserProfile({ ...userProfile, ...data });
            setSelectedGrantTypes(data.grantTypes);
            setCurrentStep(2);
        } else if (currentStep === 2) {
            setSelectedApplicants(data.eligibleApplicants);
            setCurrentStep(3);
        } else if (currentStep === 3) {
            setUserProfile({ ...userProfile, selectedGrants: data.selectedGrants });
            setCurrentStep(4);
        } else if (currentStep === 4) {
            setSelectedQuestion(data.selectedQuestion);
            setSelectedOption(data.selectedOption);
            setSelectedGrantIds(data.selectedGrantIds);
            setCurrentStep(5);
        } else if (currentStep === 5) {
            setCurrentStep(6);
        } else if (currentStep === 6) {
            setCurrentStep(7);
        }
    };

    return (
        <div className="wizard-container">
            <ProgressBar currentStep={currentStep} />
            {currentStep === 0 && <LandingPage onNext={handleNextStep} />}
            {currentStep === 1 && <StepOne onNext={handleNextStep} />}
            {currentStep === 2 && <StepTwo userProfile={userProfile} selectedGrantTypes={selectedGrantTypes} onNext={handleNextStep} />}
            {currentStep === 3 && <StepThree userProfile={userProfile} selectedGrantTypes={selectedGrantTypes} selectedApplicants={selectedApplicants} onNext={handleNextStep} />}
            {currentStep === 4 && <StepFour userProfile={userProfile} onNext={handleNextStep} />}
            {currentStep === 5 && <StepFive userProfile={userProfile} selectedQuestion={selectedQuestion} selectedOption={selectedOption} selectedGrantIds={selectedGrantIds} onNext={handleNextStep} />}
            {currentStep === 6 && <StepSix onNext={handleNextStep} />}
            {currentStep === 7 && <Confirmation />}
        </div>
    );
};

export default GrantsWizard;
