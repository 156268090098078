import React, { useState, useEffect } from 'react';
import { CircularProgress, LinearProgress, Modal, Box, Typography } from '@mui/material';
import { useSpring, animated, config } from 'react-spring';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import BotImage from '../assets/Grant_photo.webp';
import DataIcon from '@mui/icons-material/Storage';
import SearchIcon from '@mui/icons-material/Search';
import './InteractiveWizard.css';

const US_TOPO_JSON = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const botMessages = [
    "Let's get started! Where will the project be carried out?",
    "Great! What is the category of your project?",
    "Interesting. What type of grant are you looking for?",
    "Who is the target audience for your project?",
    "What is the estimated budget for the project?",
    "What is the expected duration of the project?",
    "What is the main motivation for your project?",
    "What is the main challenge your project aims to solve?",
    "In which region of the state will the project be carried out?",
    "Does the project involve collaboration with other organizations?",
    "What is the level of experience of your team?",
    "Does the project already have funding sources?",
    "What is the estimated timeline for the start of the project?",
    "What will be the main success indicators for the project?",
    "What is the degree of innovation of your project?",
    "Does the project have a long-term sustainability plan?"
];

const STATE_COLORS = {
    Alabama: "#f28e2b",
    Alaska: "#e15759",
    Arizona: "#76b7b2",
    Arkansas: "#59a14f",
    California: "#edc948",
    Colorado: "#b07aa1",
    Connecticut: "#ff9da7",
    Delaware: "#9c755f",
    Florida: "#bab0ac",
    Georgia: "#f28e2b",
    Hawaii: "#e15759",
    Idaho: "#76b7b2",
    Illinois: "#59a14f",
    Indiana: "#edc948",
    Iowa: "#b07aa1",
    Kansas: "#ff9da7",
    Kentucky: "#9c755f",
    Louisiana: "#bab0ac",
    Maine: "#f28e2b",
    Maryland: "#e15759",
    Massachusetts: "#76b7b2",
    Michigan: "#59a14f",
    Minnesota: "#edc948",
    Mississippi: "#b07aa1",
    Missouri: "#ff9da7",
    Montana: "#9c755f",
    Nebraska: "#bab0ac",
    Nevada: "#f28e2b",
    NewHampshire: "#e15759",
    NewJersey: "#76b7b2",
    NewMexico: "#59a14f",
    NewYork: "#edc948",
    NorthCarolina: "#b07aa1",
    NorthDakota: "#ff9da7",
    Ohio: "#9c755f",
    Oklahoma: "#bab0ac",
    Oregon: "#f28e2b",
    Pennsylvania: "#e15759",
    RhodeIsland: "#76b7b2",
    SouthCarolina: "#59a14f",
    SouthDakota: "#edc948",
    Tennessee: "#b07aa1",
    Texas: "#ff9da7",
    Utah: "#9c755f",
    Vermont: "#bab0ac",
    Virginia: "#f28e2b",
    Washington: "#e15759",
    WestVirginia: "#76b7b2",
    Wisconsin: "#59a14f",
    Wyoming: "#edc948"
};

const STATE_COORDINATES = [
    { name: "Alabama", coordinates: [-86.9023, 32.3182] },
    { name: "Alaska", coordinates: [-154.4931, 63.5888] },
    { name: "Arizona", coordinates: [-111.0937, 34.0489] },
    { name: "Arkansas", coordinates: [-92.3731, 34.9697] },
    { name: "California", coordinates: [-119.4179, 36.7783] },
    { name: "Colorado", coordinates: [-105.7821, 39.5501] },
    { name: "Connecticut", coordinates: [-72.7554, 41.6032] },
    { name: "Delaware", coordinates: [-75.5277, 38.9108] },
    { name: "Florida", coordinates: [-81.5158, 27.6648] },
    { name: "Georgia", coordinates: [-82.9071, 32.1656] },
    { name: "Hawaii", coordinates: [-155.5828, 19.8968] },
    { name: "Idaho", coordinates: [-114.7420, 44.0682] },
    { name: "Illinois", coordinates: [-89.3985, 40.6331] },
    { name: "Indiana", coordinates: [-86.1349, 40.2672] },
    { name: "Iowa", coordinates: [-93.0977, 41.8780] },
    { name: "Kansas", coordinates: [-98.4842, 39.0119] },
    { name: "Kentucky", coordinates: [-84.2700, 37.8393] },
    { name: "Louisiana", coordinates: [-91.9623, 30.9843] },
    { name: "Maine", coordinates: [-69.4455, 45.2538] },
    { name: "Maryland", coordinates: [-76.6413, 39.0458] },
    { name: "Massachusetts", coordinates: [-71.3824, 42.4072] },
    { name: "Michigan", coordinates: [-84.5361, 44.3148] },
    { name: "Minnesota", coordinates: [-94.6859, 46.7296] },
    { name: "Mississippi", coordinates: [-89.3985, 32.3547] },
    { name: "Missouri", coordinates: [-91.8318, 37.9643] },
    { name: "Montana", coordinates: [-110.3626, 46.8797] },
    { name: "Nebraska", coordinates: [-99.9018, 41.4925] },
    { name: "Nevada", coordinates: [-116.4194, 38.8026] },
    { name: "New Hampshire", coordinates: [-71.5724, 43.1939] },
    { name: "New Jersey", coordinates: [-74.4057, 40.0583] },
    { name: "New Mexico", coordinates: [-105.8701, 34.5199] },
    { name: "New York", coordinates: [-74.2179, 43.2994] },
    { name: "North Carolina", coordinates: [-79.0193, 35.7596] },
    { name: "North Dakota", coordinates: [-101.0020, 47.5515] },
    { name: "Ohio", coordinates: [-82.9071, 40.4173] },
    { name: "Oklahoma", coordinates: [-97.0929, 35.0078] },
    { name: "Oregon", coordinates: [-120.5542, 43.8041] },
    { name: "Pennsylvania", coordinates: [-77.1945, 41.2033] },
    { name: "Rhode Island", coordinates: [-71.4774, 41.5801] },
    { name: "South Carolina", coordinates: [-81.1637, 33.8361] },
    { name: "South Dakota", coordinates: [-99.9018, 43.9695] },
    { name: "Tennessee", coordinates: [-86.5804, 35.5175] },
    { name: "Texas", coordinates: [-99.9018, 31.9686] },
    { name: "Utah", coordinates: [-111.0937, 39.3200] },
    { name: "Vermont", coordinates: [-72.5778, 44.5588] },
    { name: "Virginia", coordinates: [-78.6569, 37.4316] },
    { name: "Washington", coordinates: [-120.7401, 47.7511] },
    { name: "West Virginia", coordinates: [-80.4549, 38.5976] },
    { name: "Wisconsin", coordinates: [-88.7879, 43.7844] },
    { name: "Wyoming", coordinates: [-107.2903, 43.0760] }
];

const InteractiveWizard = () => {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [showContent, setShowContent] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [timeLeft, setTimeLeft] = useState(8);
    const [openModal, setOpenModal] = useState(false);
    const [showSubmitButton, setShowSubmitButton] = useState(false);
    const [optionSelected, setOptionSelected] = useState(false);
    const [showStatistics, setShowStatistics] = useState(false);

    // New states for statistics
    const [processedData, setProcessedData] = useState(0);
    const [dataSources, setDataSources] = useState(0);
    const [grantsFound, setGrantsFound] = useState(0);
    const [avgProcessingTime, setAvgProcessingTime] = useState(8);

    useEffect(() => {
        const fixedQuestions = botMessages.map((message, index) => {
            return {
                id: index + 1,
                question: message,
                options: []
            };
        });
    
        fixedQuestions[1].options = [
            { label: "Education", value: "education" },
            { label: "Health", value: "health" },
            { label: "Technology", value: "technology" },
            { label: "Environment", value: "environment" },
            { label: "Art and Culture", value: "art_culture" }
        ];
    
        fixedQuestions[2].options = [
            { label: "Research", value: "research" },
            { label: "Innovation", value: "innovation" },
            { label: "Community", value: "community" },
            { label: "Business Development", value: "business_development" },
            { label: "Sustainability", value: "sustainability" }
        ];
    
        fixedQuestions[3].options = [
            { label: "Children", value: "children" },
            { label: "Teenagers", value: "teenagers" },
            { label: "Adults", value: "adults" },
            { label: "Elderly", value: "elderly" },
            { label: "Low-income Communities", value: "low_income" }
        ];
    
        fixedQuestions[4].options = [
            { label: "Less than $10,000", value: "less_than_10000" },
            { label: "$10,000 - $50,000", value: "10000_50000" },
            { label: "More than $50,000", value: "more_than_50000" },
            { label: "More than $100,000", value: "more_than_100000" }
        ];
    
        fixedQuestions[5].options = [
            { label: "Less than 6 months", value: "less_than_6_months" },
            { label: "6 months to 1 year", value: "6_months_to_1_year" },
            { label: "More than 1 year", value: "more_than_1_year" },
            { label: "2 years or more", value: "2_years_or_more" }
        ];
    
        fixedQuestions[6].options = [
            { label: "Social Impact", value: "social_impact" },
            { label: "Technological Innovation", value: "tech_innovation" },
            { label: "Education and Training", value: "education_training" },
            { label: "Economic Development", value: "economic_development" }
        ];
    
        fixedQuestions[7].options = [
            { label: "Social Inequality", value: "social_inequality" },
            { label: "Access to Education", value: "education_access" },
            { label: "Public Health", value: "public_health" },
            { label: "Climate Change", value: "climate_change" }
        ];
    
        fixedQuestions[8].options = [
            { label: "North", value: "north" },
            { label: "South", value: "south" },
            { label: "East", value: "east" },
            { label: "West", value: "west" },
            { label: "Center", value: "center" }
        ];
    
        fixedQuestions[9].options = [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
            { label: "Not defined yet", value: "not_defined_yet" }
        ];
    
        fixedQuestions[10].options = [
            { label: "Beginner", value: "beginner" },
            { label: "Intermediate", value: "intermediate" },
            { label: "Advanced", value: "advanced" },
            { label: "Expert", value: "expert" }
        ];
    
        fixedQuestions[11].options = [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
            { label: "Fundraising in progress", value: "fundraising_in_progress" }
        ];
    
        fixedQuestions[12].options = [
            { label: "Immediately", value: "immediately" },
            { label: "In 3 months", value: "in_3_months" },
            { label: "In 6 months", value: "in_6_months" },
            { label: "In 1 year", value: "in_1_year" },
            { label: "Not defined yet", value: "not_defined_yet" }
        ];
    
        fixedQuestions[13].options = [
            { label: "Community Impact", value: "community_impact" },
            { label: "Technology Adoption", value: "technology_adoption" },
            { label: "Education Improvement", value: "education_improvement" },
            { label: "Economic Growth", value: "economic_growth" }
        ];
    
        fixedQuestions[14].options = [
            { label: "Low", value: "low" },
            { label: "Medium", value: "medium" },
            { label: "High", value: "high" },
            { label: "Disruptive", value: "disruptive" }
        ];
    
        fixedQuestions[15].options = [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
            { label: "Under development", value: "under_development" }
        ];
    
        setQuestions(fixedQuestions);
    }, []);
    
    
    useEffect(() => {
        if (questions.length > 0) {
            setShowContent(true);
        }
    }, [questions]);

    useEffect(() => {
        let timer;
        if (loading && step >= 0) {
            const currentQuestion = questions[step].question;
            setLoadingMessage(`Fetching data for "${currentQuestion}"...`);
            setTimeLeft(8);
            setShowStatistics(true);
            timer = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime > 1) {
                        setProcessedData(prev => prev + Math.floor(Math.random() * 200));
                        setDataSources(prev => prev + Math.floor(Math.random() * 3));
                        setGrantsFound(prev => prev + Math.floor(Math.random() * 15));
                        return prevTime - 1;
                    } else {
                        clearInterval(timer);
                        setShowStatistics(false);
                        return 0;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [loading, step, questions]);

    useEffect(() => {
        if (step >= 0 && step < botMessages.length) {
            const currentBotMessage = botMessages[step];
            let i = 0;
            setWelcomeMessage('');
            const typingInterval = setInterval(() => {
                setWelcomeMessage(prevMessage => prevMessage + currentBotMessage[i]);
                i++;
                if (i === currentBotMessage.length) {
                    clearInterval(typingInterval);
                }
            }, 50);
    
            return () => clearInterval(typingInterval);
        }
    }, [step]);
    
    
    const handleAnswerClick = (questionId, answer) => {
        setAnswers({ ...answers, [questionId]: answer });
        setOptionSelected(true);
    };
    
    const handleStateClick = (geography) => {
        setAnswers({ ...answers, [questions[step].id]: geography.properties.name });
        setOptionSelected(true);
    };

    const handleNextStep = () => {
        setLoading(true);
        setTimeout(() => {
            if (step >= questions.length - 1) {
                setOpenModal(true);
                setTimeout(() => {
                    setShowSubmitButton(true);
                }, 10000);
            } else {
                setStep(step + 1);
            }
            setLoading(false);
            setOptionSelected(false);
        }, 8000);
    };
    
    
    const handleCloseModal = () => setOpenModal(false);

    const fadeProps = useSpring({
        opacity: showContent && !loading ? 1 : 0,
        config: config.molasses
    });

    return (
        <div className="container">
            <div className="contentContainer">
                <div className="questionContainer">
                    <div className="fixedTopContainer">
                        <div className="progressContainer">
                            <div className="progressBar">
                                <LinearProgress 
                                    variant="determinate" 
                                    value={(step / questions.length) * 100} 
                                    sx={{ backgroundColor: 'lightgray', '& .MuiLinearProgress-bar': { backgroundColor: 'orange' } }}
                                />
                            </div>
                            <div className="progressText">
                                {`Step ${step + 1} of ${questions.length} (${Math.round((step / questions.length) * 100)}%)`}
                            </div>
                            
                            {!loading && (
                                <div className="botContainer">
                                    <img src={BotImage} alt="Bot" className="botImage" />
                                    <h2 className="message">{welcomeMessage}</h2>
                                </div>
                            )}
                        </div>
                    </div>
    
                    {!loading ? (
                        <>
                            {showContent && step < questions.length && questions[step] && (
                                <animated.div style={fadeProps}>
                                    <div className="answersContainer">
                                        {questions[step].id === 1 ? (
                                            <ComposableMap projection="geoAlbersUsa" className="map">
                                                <Geographies geography={US_TOPO_JSON}>
                                                    {({ geographies }) =>
                                                        geographies.map(geo => (
                                                            <Geography
                                                                key={geo.rsmKey}
                                                                geography={geo}
                                                                onClick={() => handleStateClick(geo)}
                                                                style={{
                                                                    default: { fill: STATE_COLORS[geo.properties.name.replace(/\s+/g, '')], outline: "none" },
                                                                    hover: { fill: "#F53", outline: "none" },
                                                                    pressed: { fill: "#E42", outline: "none" }
                                                                }}
                                                            />
                                                        ))
                                                    }
                                                </Geographies>
                                                {STATE_COORDINATES.map(({ name, coordinates }) => (
                                                    <Marker key={name} coordinates={coordinates}>
                                                        <text y="2" fontSize={10} textAnchor="middle">
                                                            {name}
                                                        </text>
                                                    </Marker>
                                                ))}
                                            </ComposableMap>
                                        ) : (
                                            questions[step].options.map((option, index) => (
                                                <div
                                                    key={index}
                                                    className={`answerBubble ${answers[questions[step].id] === option.value ? 'selected' : ''}`}
                                                    onClick={() => handleAnswerClick(questions[step].id, option.value)}
                                                >
                                                    {option.label}
                                                </div>
                                            ))
                                        )}
                                    </div>
                                    {optionSelected && (
                                        <button className="nextButton" onClick={handleNextStep}>Next</button>
                                    )}
                                </animated.div>
                            )}
                        </>
                    ) : (
                        <div className="loadingContainer">
                            <div className="loader">
                                <div className="iconContainer">
                                    <DataIcon className="dataIcon" />
                                    <SearchIcon className="searchIcon" />
                                </div>
                            </div>
                            <p className="loadingText">{loadingMessage}</p>
                            <CircularProgress color="primary" />
                            {showStatistics && (
                                <div className="statisticsContainer">
                                    <p>Processed data: <strong>{processedData.toLocaleString()}</strong> entries</p>
                                    <p>Data sources analyzed: <strong>{dataSources}</strong></p>
                                    <p>Grant suggestions found: <strong>{grantsFound}</strong></p>
                                    <p>Average processing time: <strong>{avgProcessingTime}</strong> seconds</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="reportContainer">
                    <h3>Grants Selection</h3>
                    <ul className="reportList">
                        {Object.keys(answers).map((key, index) => (
                            <li key={index} className="reportListItem">
                                <input type="checkbox" checked readOnly className="checkbox" />
                                <span>{questions.find(q => q.id === parseInt(key)).question}: {answers[key]}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box className="modalBox">
                    <Typography variant="h6" component="h2">
                        Analyzing data with AI for better opportunities...
                    </Typography>
                    <CircularProgress size={60} />
                    <LinearProgress color="primary" className="modalProgressBar" />
                    {showSubmitButton && (
                        <button className="submitButton" onClick={() => window.location.href = 'test/login'}>subscription to see data</button>
                    )}
                </Box>
            </Modal>
        </div>
    );
    
};

export default InteractiveWizard;
